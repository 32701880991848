.products-container {
	display: flex;
	padding: 5rem 4rem;
}
.product-pic-container {
	width: 38%;
}
.product-pic {
	width: 100%;
}

.indicator-container {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.indicator {
	background-color: #968d8d;
	height: 10px;
	width: 10px;
	margin: 10px 5px;
	border-radius: 50%;
}

.product-description-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.product-id-container {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

.product-id {
	width: fit-content;
	font-size: 1.7rem;
}

.product-price {
	font-size: 1.4rem;
	width: fit-content;
	padding-right: 5%;
}

.product-description-container div {
	display: flex;
	margin-bottom: 0.5rem;
}

.product-title {
	font-size: 1.4rem;
	color: var(--orange);
}

.product-des {
	font-size: 1rem;
	padding: 0;
	margin: 0;
}

.add-to-cart-container {
	display: flex;
	flex-direction: column;
	width: 95%;
	align-items: flex-end;
	justify-content: flex-end;
}

.color-option-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 3%;
}

.add-to-cart-btn:hover {
	cursor: pointer;
}
