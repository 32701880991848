.login {
	display: flex;
	align-items: center;
	justify-content: center;
}

.form-root-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60%;
	padding: 5% 0;
	border-radius: 5%;
	background-color: var(--secondary);
	box-shadow: 11px 10px 11px 0px rgba(255, 255, 255, 0.75);
}

.form-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	justify-content: center;
	padding: 0 5%;
}

.form-root-container .left {
	border-right: 1px solid white;
}

.title {
	font-size: 2.2rem;
}

.input-container {
	display: flex;
	width: 100%;
	min-height: 30px;
	margin-top: 15%;
	justify-content: space-between;
	align-items: center;
}

.input-container img {
	scale: 1.8;
}

input {
	min-height: 30px;
	width: 90%;
	border: none;
	background-color: transparent;
	color: white;
	border-bottom: 1px solid white;
}

input:focus {
	outline: none;
	box-shadow: none;
	border-bottom: 1px solid var(--orange);
}

button {
	min-height: 30px;
	width: 100%;
	margin-top: 15%;
}

.change-button {
	margin-top: 15%;
}
.change-button:hover {
	text-decoration: underline;
}

.pic {
	width: 80%;
}
