/* header */
header {
	display: flex;
	flex-direction: row;
	padding: 3rem 2rem 1.5rem 0;
}

.logo-container {
	margin-left: 0;
}

.logo-container img {
	max-width: 260px;
}

@media (max-width: 768px) {
	header {
		display: flex;
		flex-direction: row;
		padding: 3rem 0.5rem 1.5rem 0;
	}

	.header-logo {
		width: 50vw;
		margin-left: 14%;
	}
	.menu-container {
		display: none;
		flex-direction: column;
		width: 50vw;
		position: fixed;
		right: 0;
		top: 0;
		bottom: 0;
		height: 100vh;
		font-size: 1.1rem;
		align-items: center;
		background-color: rgba(
			var(--primary-r),
			var(--primary-g),
			var(--primary-b),
			0.9
		);
		padding-top: 10%;
	}

	.mobile-menu {
		display: flex;
		flex: 1;
		justify-content: flex-end;
		align-items: center;
	}

	.mobile-menu .menu-icon {
		margin: 0 1.2rem;
		width: 1.8rem;
	}

	.menu-link-border {
		margin: 0.5rem 0;
	}
	.menu-icon-border {
		margin-top: 0.5rem;
		padding: 0.8rem 0;
	}
	.menu-icon {
		width: 1.2rem;
	}
}

@media (min-width: 768px) {
	.header-logo {
		width: 50vw;
	}
	.menu-container {
		display: flex;
		flex: 1;
		font-size: 1.1rem;
		align-items: flex-end;
		justify-content: flex-end;
	}

	.menu-link-border {
		border-right: 2px solid #6f6f6f;
		padding: 0 1rem;
	}

	.menu-icon-border {
		border-right: 2px solid #6f6f6f;
		padding: 0 1.2rem;
	}

	.menu-icon {
		width: 100%;
		scale: 1.7;
	}
}

.menu-link {
	color: #c3c3c3;
	padding-bottom: 0.3rem;
}

.menu-link:hover {
	border-bottom: 3px solid var(--orange);
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: rgba(
		var(--primary-r),
		var(--primary-g),
		var(--primary-b),
		0.7
	);
	width: 80%;
	max-width: 800px;
	left: 50%;
	transform: translateX(-50%);
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	top: var(--headerHeight);
}

.menu-link-border:hover .dropdown-content,
.menu-link-border:focus-within .dropdown-content,
.dropdown-content:hover,
.dropdown-content:focus-within {
	display: block;
}

.dropdown-item {
	color: white;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown-item:hover {
	background-color: #ddd;
}

/* footer */
footer {
	display: flex;
	/* padding: 3rem 2rem 1.5rem 1.5rem; */
	padding: 2rem 0.5rem;
}

.app-icon-container {
	display: flex;
	flex-direction: column;
	margin-left: 3%;
	width: 8%;
	justify-content: center;
	align-items: center;
}

.app-links-container {
	display: flex;
	flex-direction: column;
	width: 15%;
	justify-content: space-around;
	align-items: center;
}

.app-link-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 88%;
	height: 35%;
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 6%;
}

.app-link-text {
	margin-left: 4%;
	width: 60%;
}

.footer-menu-container {
	flex: 1;
	display: flex;
	align-items: flex-end;
	justify-content: space-around;
	flex-direction: column;
	margin-bottom: 10%;
}

.footer-menu-list-container {
	display: flex;
	height: 30%;
	justify-content: center;
	align-items: flex-end;
}

.footer-menu-item {
	padding: 0 1rem;
	color: white;
	font-size: 1.1rem;
	margin-bottom: 3%;
}

.footer-menu-item:hover {
	text-decoration: underline;
}

.media-icon {
	margin-top: 10%;
	height: 30px;
}
