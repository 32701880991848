.title-container{
    display: flex;
    padding:2%;
    justify-content: center;
    align-items: center;    
}

.policy-arrow{
    margin-left: 5%;
    height:2%;
    width:2%;

}

.policy-title{
    flex:1;
    font-size: 1.2rem;
}

.policy-content-container{
    padding:0 10vw
}