.inner-page-title-box-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: -1px;
	width: 100%;
	justify-content: flex-end;
	@media (max-width: 799px) {
		height: calc(1182 / 2924 * 95vw);
	}
	@media (min-width: 799px) {
		/*root-margin width */
		height: calc(1182 / 2924 * 85vw);
		min-height: calc(1182 / 2924 * 200px);
		max-height: calc(1182 / 2924 * 1000px);
	}
}

.inner-page-title-box {
	color: var(--blue);
	border-right-color: var(--orange);
	border-right-style: solid;
	height: fit-content;
	margin-bottom: 5%;
	padding-right: 5%;
	width: 20%;
	text-align: end;
	font-size: 1.6rem;
	@media (max-width: 799px) {
		padding-top: 0%;
		font-size: 16px;
		width: 30%;
	}
}

.inner-page-content-box{
	display: flex;
	background-color: rgb(217,217,217);
	padding: 2rem;
	flex: 1;
}

.inner-page-slogan{
    border-right: 2px solid var(--orange);
    flex: 1;
    color: black;
    font-size: 1.1rem;
    padding-right: 5%;
	width: 20%;
}

.inner-page-content{
    flex: 3;
    color: black;
    text-align: left;
    margin-left: 3%;
    font-size: 1.1rem;
}

.inner-page-sub-title{
    color: black;
    font-size: 1.4rem;
    margin-top: 0 2% 1% 1%;
}