@media (min-width: 768px) {
	html {
		font-size: 18px; /* Base font size for tablets */
	}
}

@media (max-width: 768px) {
	html {
		font-size: 11px; /* Base font size for mobile devices */
	}
}

.App {
	text-align: center;
	background-color: var(--primary);
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
		Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.root-margin {
	width: 100%;
	max-width: var(--rootMaxWidth);
	min-width: var(--rootMinWidth);
	/* @media (min-width: 768px) {
		width: 80%;
	}
	@media (max-width: 768px) {
		width: 95%;
	} */
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: var(--primary);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

Link {
	color: #fff;
}

:root {
	--primary: rgb(40, 44, 52);
	--primary-r: 40;
	--primary-g: 44;
	--primary-b: 52;
	--secondary: rgb(35, 37, 51);
	--white: rgba(255, 255, 255, 0.521);
	--blue: #40a1a7;
	--blue-deeper: #2a798a;
	--orange: #f08519;
	--fontFamily: 'Open Sans', sans-serif;
	--rootMaxWidth: 1500px;
	--rootMinWidth: 200px;
	--dark-grey: #494949;
}

.page-root {
	width: 100%;
	min-height: calc(100vh - var(--footerHeight) - var(--headerHeight));
}

.border-btn {
	border: 3px solid var(--white);
	border-radius: 10px;
	padding: 1.5% 3%;
	color: var(--white);
	text-align: center;
}

.border-btn:hover {
	color: white;
	border-color: white;
	cursor: pointer;
}

.btn:hover {
	cursor: pointer;
}

.orange-color {
	color: var(--orange);
}
