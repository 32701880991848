.user-page-root-margin {
	margin: 0 10%;
	display: flex;
}

.login-btn {
	background-color: white;
	color: var(--primary);
	padding: 0.5rem 1rem;
	border-radius: 3%;
}

.profile-header-container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 10% 0;
}

.profile-email {
	color: var(--orange);
	font-size: 1.3rem;
}

.profile-logout:hover {
	cursor: pointer;
}

.user-page-index-container {
	width: 40%;
}
.user-page-contents-container {
	width: 100%;
	flex: 1;
	padding: 5% 3%;
}

.profile-tab-container {
	text-align: left;
	padding: 5% 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.521);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.profile-tab-container:hover {
	cursor: pointer;
}

.arrow-btn {
	width: 15px;
	margin-right: 3%;
}

.arrow-btn:hover {
	cursor: pointer;
}

/* content component */
.user-page-content-container {
	display: flex;
	flex-direction: column;
}
.sub-content-container {
	display: flex;
	padding: 2% 0;
	align-items: center;
	width: 100%;
}

.content-input-label {
}

.user-input {
	flex: 1;
	margin-left: 10;
}

.comfirm-box-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.confirm-btn {
	margin: 1.5rem 0;
	width: fit-content;
}

.content-row-text {
	text-align: left;
	margin: 1rem 0;
}
