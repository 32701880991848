.banner {
	background: rgb(217, 217, 217) url(../../public/locker2.png) no-repeat top
		center;
	background-size: contain;
	display: flex;
	width: 100%;
	@media (min-width: 799px) {
		height: calc(3172 / 5906 * 92%);
		min-height: calc(3172 / 5906 * 200px);
		max-height: calc(3172 / 5906 * 1500px);
		justify-content: space-between;
		padding-left: 8%;
		width: 92%;
		align-items: center;
	}
	@media (max-width: 799px) {
		height: fit-content;
		flex-direction: column;
		align-items: space-between;
	}
}

.banner .title {
	font-size: 1.2rem;
	color: #c3c3c3;
}

.banner .content {
	text-align: left;
	margin-bottom: 2%;
	font-size: 1rem;
	color: #c3c3c3;
}

.banner-logo-container {
	width: fit-content;
	height: calc(3172 / 5906 * 100vw);
	display: flex;
	align-items: center;
	@media (max-width: 799px) {
		margin-left: 8%;
	}
}

.text-logo {
	height: calc(3172 / 5906 * 100vw * 0.4);
	max-height: 300px;
	width: fit-content;
}

.banner-content {
	background-color: rgba(0, 0, 0, 0.4);
	width: 50%;
	padding: 3%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: fit-content;

	@media (max-width: 768px) {
		width: 94%;
	}
}

.entry-content-table-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: rgb(217, 217, 217);
	@media (min-width: 500px) {
		padding: 0 4rem;
	}
	@media (max-width: 500px) {
		padding: 0 5%;
	}
}

.content-table-slogan-container {
	display: flex;
	margin-top: 4%;
	align-items: flex-end;
	@media (max-width: 500px) {
		flex-direction: column;
		align-items: center;
	}
}

.entry-link-box {
	min-width: 270;
	@media (max-width: 500px) {
		width: 90vw;
		margin: 2% 0;
	}

	@media (min-width: 500px) {
		width: 31%;
		margin: 2% 0%;
	}
}

.grid-container {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	@media (max-width: 500px) {
		flex-direction: column;
		justify-content: center;
	}
}

.app-container {
	@media (min-width: 500px) {
	}
	@media (max-width: 500px) {
		flex-direction: column;
	}
}

.app-icon-container-mobile {
	@media (min-width: 500px) {
		width: 40%;
	}
	@media (max-width: 500px) {
		max-height: 200px;
		flex-direction: column;
		width: 95vw;
		height: fit-content;
		margin-top: 3%;
	}
}

.app-badge {
	@media (max-width: 500px) {
		max-height: 30px;
		margin: 10% 0;
	}
}

.app-icon {
	@media (max-width: 500px) {
		max-height: 100px;
		margin-right: 5%;
	}
}
.shop-now-container {
	padding: 0 4rem;
	background-color: grey;
	@media (max-width: 500px) {
		padding: 0 5%;
	}
}

.slogan-container {
	display: flex;
	padding-top: 5%;
	align-items: flex-end;
	@media (max-width: 500px) {
		flex-direction: column;
		align-items: flex-start;
	}
}

.products-pic-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: fit-content;
	@media (max-width: 500px) {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.products-pic {
	@media (min-width: 500px) {
		min-width: 250px;
		width: 48%;
		margin-top: 2%;
	}
	@media (max-width: 500px) {
		width: 100%;
		margin-top: 5%;
	}
}
